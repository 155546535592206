<template>
  <div>
    <div class="content">
      <div
        v-if="usuario.cpf && $route.name != 'Home'"
        class="px-5 py-2"
        style="
          padding: 10px 50px;
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
      >
      <div style="display: flex; align-items: center; gap: 10px">
      
          <b-button @click="$router.push({ name: 'Home' })" variant="link" v-if="$route.name == 'ListarDars'"> 
            <b-icon icon="house-fill" variant="primary"></b-icon>
          </b-button>
         
        </div>
        <div style="display: flex; align-items: center; gap: 10px">
          <div>
            <strong style="margin-right: 5px">CPF:</strong>
            {{ usuario.cpf | VMask("###.###.###-##") }}
          </div>
          <b-button @click="sair()" variant="outline-secondary">Sair</b-button>
        </div>
      </div>
      <b-container fluid>
        <b-container fluid="sm">
          <b-form-row>
            <router-view></router-view>
          </b-form-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<script>
import { logout } from "@/middlewares/updateToken";

export default {
  name: "MyContent",
  computed: {
    usuario() {
      return this.$store.getters.getUsuario;
    },
  },
  methods: {
    sair() {
      logout();
    },
  },
};
</script>

<style>
.content {
  grid-area: content;
  padding: 20px 0;
}
</style>
