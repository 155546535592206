<template>
  <div class="w-100 my-3">
    <div class="jumbotron jumbotron-fluid py-3">
      <div class="container lead">
        <ul>
          <li>
            Lista de Documentos de Arrecadação de Receitas (DAR) emitidos no
            seu CPF com a finalidade de obter a 2ª via da Carteira de Identidade
            Nacional (CIN).
          </li>
          <li>
            Caso o Documento de Arrecadação de Receitas (DAR) esteja
            vencido,
            <a
              style="
                text-decoration: underline;
                color: #007bff;
                cursor: pointer;
              "
              @click.prevent="$router.push('/via1')"
              variant="link"
              >clique aqui</a
            >
            para começar um novo agendamento.
          </li>
        </ul>
      </div>
    </div>

    <b-container>
      <b-row class="mt-1">
        <b-col> </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-overlay
            :show="showOverlay"
            variant="transparent"
            opacity="0.9"
            blur="2px"
            rounded="sm"
          >
            <b-card class="mb-2">
              <template #header>
                <div style="display: flex; justify-content: space-between">
                  <h4 class="mb-0">DARs emitidos</h4>
                </div>
              </template>
              <b-card-text class="text-center">
                <b-table
                  ref="table"
                  striped
                  hover
                  :items="lista"
                  :fields="fields"
                  v-if="Object.keys(lista).length"
                >
                  <template #cell(nrCodBarras)="cell">
                    <div
                      title="Clique para copiar o código de barras"
                      @click="copyToClipboard(cell.value)"
                      v-if="cell.value !== ''"
                      style="
                        font-size: 15px;
                        text-decoration: underline;
                        color: black;
                        cursor: pointer;
                      "
                    >
                      <div
                        v-for="(codigo, i) in cell.value.split(' ')"
                        :key="i"
                      >
                        {{ codigo }}
                      </div>
                    </div>
                  </template>

                  <template #cell(status)="cell">
                    <a
                      v-if="cell.value.status == null"
                      href="#"
                      @click.prevent="
                        consultarPagamentoDAR(cell.value.nossoNumero)
                      "
                      >verificar</a
                    >
                    <span v-else>{{ cell.value.status }}</span>
                    <span v-if="cell.value.status === 'QUITADO'">
                      <br />
                      <a
                        href="#"
                        @click.prevent="proximoPasso(cell.value.nossoNumero)"
                      >
                        Ir para o agendamento
                      </a>
                    </span>
                  </template>
                </b-table>
                <div v-else>Nenhum registro encontrado.</div>
              </b-card-text>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import environment from "@/environment";
import axios from "axios";
import ToastMixin from "@/mixins/toastMixin";

export default {
  name: "ListarDars",
  mixins: [ToastMixin],
  data: function () {
    return {
      lista: [],
      fields: [
        { key: "dtEmissao", label: "Data de vencimento" },
        { key: "nrCodBarras", label: "Código de barras" },
        { key: "nrNossoNumero", label: "Nosso número" },
        { key: "valor", label: "Valor R$" },
        { key: "status", label: "Status" },
      ],
      showOverlay: false,
    };
  },
  computed: {
    usuario: {
      get() {
        return this.$store.getters.getUsuario;
      },
      set(valor) {
        this.$store.commit("setUsuario", valor);
      },
    },
  },
  methods: {
    consultarPagamentoDAR(status) {
      this.showOverlay = true;
      axios
        .get(
          `${environment.BASE_URL}/dar/cpf/${
            this.usuario.cpf
          }/dar/${status.trim()}`,
          { isAuthenticated: true }
        )
        .then((res) => {
          if (res.data.status === "FAIL") {
            this.makeToast(res.data.message, "Erro", "danger");
          } else {
            this.lista.forEach((el) => {
              if (el["nrNossoNumero"] == status) {
                el["status"].status = res.data.result.stLancamento;
                this.$refs.table.refresh();
              }
            });
          }
          this.showOverlay = false;
        })
        .catch((error) => {
          this.makeToast(error, "Erro", "danger");
          this.showOverlay = false;
        });
    },
    proximoPasso(nossoNumero) {
      this.$store.commit("setVia", {
        n: 2,
        comAlteracao: null,
        nossoNumero: nossoNumero,
      });
      this.$store.commit("passoDadosAgendamento");
      this.$router.push("/agendamento");
    },
    fazerAgendamento() {
      this.$store.commit("setVia", {
        n: 1,
        comAlteracao: null,
        nossoNumero: null,
      });
      this.$store.commit("passoDadosAgendamento");
      this.$router.push("/agendamento");
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.makeToast("Copiado", "Sucesso", "success");
    },
  },
  mounted() {
    this.showOverlay = true;
    axios
      .get(`${environment.BASE_URL}/dar/emitidos`, {
        isAuthenticated: true,
      })
      .then((res) => {
        this.lista = res.data;
        this.lista.forEach((el) => {
          // Exibir código de barras apenas se não tiver vencido
          const parts = el["dtEmissao"].split("-");
          const vencimento = new Date(parts[0], parts[1] - 1, parts[2]);
          vencimento.setDate(vencimento.getDate() + 3);
          const agora = new Date();

          if (!(agora <= vencimento)) {
            el["nrCodBarras"] = "";
          }

          el["dtEmissao"] = this.$tempo.formatarStringDataAdicionandoDias(
            el["dtEmissao"],
            2
          );
          delete el["linhaDigitavel"];

          if (el["status"] != "EM ABERTO") {
            const s = el["status"];
            el["status"] = { nossoNumero: el["nrNossoNumero"], status: s };
          } else {
            el["status"] = { nossoNumero: el["nrNossoNumero"], status: null };
          }
        });
        this.showOverlay = false;
      })
      .catch((error) => {
        this.makeToast(error, "Erro", "danger");
        this.showOverlay = false;
      });
  },
};
</script>

<style>
.codBarras {
  padding: 1px 2px 3px !important;
  font-size: small;
  border: none;
  vertical-align: inherit;
}
</style>
