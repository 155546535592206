import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/home/Home'
import Via1 from '@/components/home/Via1'
import Agendamento from '@/components/agendamento/Agendamento'
import Comprovante from '@/components/utils/report/Comprovante'
import DarReport from '@/components/utils/report/DarReport'
import ListarDars from '@/components/dar/Listar'
import Informacoes from '@/components/home/Informacoes.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
}, {
    path: '/via1',
    component: Via1,
    meta: { isAuthenticated: true }
}, {
    path: '/informacoes',
    component: Informacoes
}, {
    path: '/agendamento',
    component: Agendamento,
    meta: { isAuthenticated: true }
}, {
    path: '/comprovante',
    component: Comprovante,
    meta: { template: 'no-template' }
}, {
    path: '/dar',
    component: DarReport,
    meta: { template: 'no-template' }
}, {
    path: '/dar/listar',
    component: ListarDars,
    name: 'ListarDars',
    meta: { isAuthenticated: true }
}]

const router = new VueRouter({
    mode: 'history',
    routes: routes
})

import { updateToken } from "@/middlewares/updateToken";

router.beforeEach((to, from, next) => {
    if (to.meta.isAuthenticated) {
        updateToken(window.location.origin + to.path, function () {
            next()
        });
    } else {
        // This page did not require authentication
        next()
    }
})

export default router